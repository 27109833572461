<style scoped>
.add-contact-icon{
  margin-left: 5px;
  cursor: pointer;
}
</style>

<template>
    <div>
        <Divider dashed><span class="divider-text">创建订单</span></Divider>
        <div>
            <Row class="p-b-5">
                <i-col span="6">客户：</i-col>
                <i-col span="18">
                    <Select size="small" v-model="orderBaseData.advertiserId" style="width:89%" filterable clearable placeholder="请选择一个客户" @on-change="customerChange">
                        <Option v-for="(customer,index) in customers"  :value="customer.value"  :key="index">{{customer.label}}</Option>
                    </Select>
                </i-col>
            </Row>
            <Row class="p-b-5">
                <i-col span="6">投放品牌：</i-col>
                <i-col span="18">
                    <Select size="small" v-model="orderBaseData.brandId" style="width:89%" filterable placeholder="请选择一个投放品牌">
                        <Option v-for="brand in brands" :value="brand.id"  :key="brand.id">{{brand.name}}</Option>
                    </Select>
                </i-col>
            </Row>
            <Row class="p-b-5">
                <i-col span="6">联系人<span v-if="orderContact">(可空)</span>：</i-col>
                <i-col span="18">
                    <Select size="small" v-model="orderBaseData.contactId" style="width:89%" placeholder="请选择一个联系人">
                        <Option v-for="contact in contacts" :value="contact.id" :key="contact.id">{{contact.name}}</Option>
                    </Select>
                    <Icon v-if="showAddContactButton" type="md-add" color="#4285f4" @click="handlAddContact" class="add-contact-icon" size="21" />
                </i-col>
            </Row>
            <Row class="p-b-5">
                <i-col span="6">订单备注：</i-col>
                <i-col span="18">
                    <Input v-model="orderBaseData.remark" type="textarea"  style="width:95%" :rows="4" placeholder="请输入订单描述备注信息..." />
                </i-col>
            </Row>
            <Row class="p-b-5 p-t-5" v-if="orderOptionalService">
              <i-col span="6">不确定方案：</i-col>
              <i-col span="18">
                <i-switch v-model="orderfixed" true-color="#13ce66" size="large">
                    <span slot="open">开启</span>
                    <span slot="close">关闭</span>
                </i-switch>
              </i-col>
            </Row>
            <Row class="p-b-5 p-t-5"  v-show="orderOptionalService">
              <i-col span="6">定位服务：</i-col>
              <i-col span="18">
                <i-switch v-model="orderBaseData.fixProtect" true-color="#13ce66" size="large">
                    <span slot="open">开启</span>
                    <span slot="close">关闭</span>
                </i-switch>
                <ul class="p-t-5 remark">
                  <li>您可以选择已售出但未选该服务的资源</li>
                  <li>您所选资源将不会被其他商业客户买走</li>
                  <li>额外收取您一定的订单发布费</li>
                </ul>
              </i-col>
            </Row>

            <Row class="p-b-5 p-t-10">
                <i-col span="6">发布档期：</i-col>
                <i-col span="18">
                    <div @click="changeStandard(1)" :class="orderBaseData.standard === 1?'workplatform-radio-button-active':'workplatform-radio-button'" class="p-l-20 p-r-20">常规档期</div>
                    <div @click="changeStandard(2)" :class="orderBaseData.standard === 2?'workplatform-radio-button-active':'workplatform-radio-button'" class="p-l-20 p-r-20">自定义档期</div>
                </i-col>
            </Row>
            <div v-if="orderBaseData.standard === 2" class="p-b-5">
              <Row v-for="(item,index) in customSchduleList" :key="index">
                <i-col span="16" offset="6">
                  <p>
                    <span class="title">开始日期</span> <DatePicker size="small" transfer class="text-black" v-model="item.startDate" :editable="false" type="date"
                      style="width: 150px" placement="bottom-start" :options="customScheduleLimit" placeholder="请选择开始档期"></DatePicker>
                  </p>

                  <p class="m-t-5">
                    <RadioGroup v-model="item.type">
                        <Radio :label="1">默认</Radio>
                        <Radio :label="2">固定周期</Radio>
                        <Radio :label="3">自定义天数</Radio>
                    </RadioGroup>
                  </p>

                  <p class="m-t-5">
                    <template v-if="item.type===1">
                      <span class="title">结束日期</span> <DatePicker size="small" transfer class="text-black" v-model="item.endDate" :editable="false" type="date"
                        style="width: 150px" placement="bottom-start" :options="customScheduleLimit" placeholder="请选择结束档期"></DatePicker>

                      <p class="m-t-5"><span class="title">发布天数</span><span class="text-orange m-l-5">{{getPublishDay(item)}}</span></p>
                      <Alert v-if="getPublishDay(item)===''" type="error" class="m-t-5">结束日期不能小于开始日期</Alert>
                    </template>

                    <template v-else-if="item.type===2">
                      <span class="title">发布时长 </span>
                      <Select v-model="item.period" size="small" style="width:150px">
                          <Option :value="1">一周（7天）</Option>
                          <Option :value="2">四周（28天）</Option>
                          <Option :value="3">十二周（84天）</Option>
                          <Option :value="4">半年（182天）</Option>
                          <Option :value="5">一年（364天）</Option>
                      </Select>
                      <p class="m-t-5"><span class="title">结束日期</span><span class="text-orange m-l-5">{{getPublishEndDate(item)}}</span></p>
                    </template>

                    <template v-else>
                      <span class="title">发布天数 </span>
                      <InputNumber v-model="item.publishDay" size="small" :min="1"></InputNumber> 天
                      <p class="m-t-5"><span class="title">结束日期</span><span class="text-orange m-l-5">{{getPublishEndDateByDay(item)}}</span></p>
                    </template>
                  </p>
                </i-col>
                <i-col span="2" class="p-t-20">
                  <Icon type="ios-trash-outline" color="red" size="20" class="schedule-delete" @click="handleDeleteCustomSchedule(index)"/>
                </i-col>
                <i-col span="18" offset="6"><Divider style="margin: 10px 0 5px 0;" /></i-col>
              </Row>
              <Row>
                <i-col span="18" offset="6">
                  <a @click="handleAddCustomSchedule"><Icon type="md-add" />添加新的档期</a>
                </i-col>
              </Row>
            </div>
        </div>

        <Row v-if="orderBaseData.standard === 1" class="p-t-10 p-l-5">
            <CheckboxGroup v-model="checkScheduleIndex" size="small">
                <Checkbox class="m-b-5" v-for="(item,index) in schedules" :key="index" :label="item.value" border>{{item.label}}</Checkbox>
            </CheckboxGroup>
            <p class="remark m-t-5">tips: “X月上”，代表投放档期为X月1日-X月14日；“X月下”，代表投放档期为X月15日-X月28日。</p>
        </Row>
        <Row class="p-t-20">
            <i-col span="24" class="text-right p-r-10">
                <Button type="success" style="width:120px" :disabled="disabledNext" :loading="loadingAddOrder" @click="handleCreateOrder">
                  <span v-if="!loadingAddOrder">生成订单</span>
                  <span v-else>生成中...</span>
                </Button>
            </i-col>
        </Row>

        <Modal v-model="modalNewContact" width="400" title="添加联系人">
          <Form ref="contactData" :model="contactData" :rules="ruleValidate" label-position="left">

            <Row :gutter="10">
              <i-col span="12">
                <FormItem label="联系人姓名" prop="name">
                  <Input v-model="contactData.name" placeholder="联系人姓名" />
                </FormItem>
              </i-col>
              <i-col span="12">
                <FormItem label="联系电话" prop="phone">
                  <Input v-model="contactData.phone" placeholder="联系电话" />
                </FormItem>
              </i-col>
            </Row>
            <Row :gutter="10">
                <i-col span="12">
                  <FormItem label="部门" prop="department">
                <Input v-model="contactData.department" placeholder="部门" />
              </FormItem>
                </i-col>
                <i-col span="12">
                  <FormItem label="职务" prop="position">
                <Input v-model="contactData.position" placeholder="职务" />
              </FormItem>
                </i-col>
            </Row>
          </Form>

          <div slot="footer">
              <Button type="success" size="small" @click="contactHandleSubmit('contactData')">新增联系人</Button>
          </div>
        </Modal>
    </div>
</template>

<script>
import { getAvailableAdvertiserPage, addContact, getContactList, getAdvertiserPage } from '@/api/crm/advertiser'
import { getBrandList } from '@/api/crm/brand'
import { addOrder } from '@/api/order/order'

import { GetPrevSchedule, GetDateStr, GetPublishDay, ParseDate } from '@/utils/dateFormat'

export default {
  props: {
    useObject: { // 使用对象，1：销售，2：代理商
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      userId: this.$store.getters.token.userInfo.userId,
      orderOptionalService: this.$store.getters.token.publisherSetting.orderOptionalService, // 是否显示增值服务
      orderContact: this.$store.getters.token.publisherSetting.orderContact, // 联系人是否为可选项
      customers: [],
      brands: [],
      contacts: [],
      loadingAddOrder: false, // 生成订单中
      searchCustomerQuery: {
        pageNumber: 1,
        pageSize: 100,
        keyword: '',
        userId: '',
        advertiserId: ''
      },
      schedules: [],
      customSchduleList: [{
        startDate: '',
        endDate: '',
        type: 1, // 自定义类型
        period: 1, // 固定周期类型
        publishDay: 1
      }],
      orderfixed: false,
      orderBaseData: {
        advertiserId: '',
        contactId: '',
        fixProtect: false, // 定位保护
        standard: (this.$store.getters.token.publisherSetting.defaultOrderSchedule === -1) ? 1 : this.$store.getters.token.publisherSetting.defaultOrderSchedule,
        userId: this.$store.getters.token.userInfo.userId,
        brandId: '',
        remark: '',
        schedules: '',
        fixed: true // 不确认方案  默认是true，确认保护，需要单独处理
      },
      checkScheduleIndex: [],
      customScheduleLimit: {},
      modalNewContact: false,
      contactData: {
        advertiserId: '',
        name: '',
        position: '',
        department: '',
        phone: '',
        userId: this.$store.getters.token.userInfo.userId
      },
      ruleValidate: {
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        position: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        department: [{ required: true, message: ' ', trigger: 'blur' }],
        phone: [{ required: true, message: ' ', trigger: 'blur' },
          { pattern: /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/, message: ' ' }]
      }
    }
  },
  created () {
    this.schedules = GetPrevSchedule(this.$store.getters.token.publisherSetting.advancePurchaseTime)
    this.initCustomOption()
    if (this.useObject === 1) {
      this.initCurrentCustomers()
    } else {
      this.getAllAdvertiserData()
    }
  },
  methods: {
    getPublishDay (schedule) {
      if (schedule.startDate === '' || schedule.endDate === '') {
        return '-'
      } else {
        return GetPublishDay(schedule.startDate, schedule.endDate)
      }
    },
    getPublishEndDate (schedule) {
      if (schedule.startDate === '') {
        return '-'
      } else {
        let endDate = ''
        if (schedule.period === 1) { // 一周
          endDate = GetDateStr(schedule.startDate, 7)
        } else if (schedule.period === 2) { // 四周
          endDate = GetDateStr(schedule.startDate, 28)
        } else if (schedule.period === 3) { // 十二周
          endDate = GetDateStr(schedule.startDate, 84)
        } else if (schedule.period === 4) { // 半年
          endDate = GetDateStr(schedule.startDate, 182)
        } else if (schedule.period === 5) { // 一年
          endDate = GetDateStr(schedule.startDate, 364)
        }

        schedule.endDate = endDate
        return endDate
      }
    },
    getPublishEndDateByDay (schedule) {
      if (schedule.startDate === '') {
        return '-'
      } else {
        const endDate = GetDateStr(schedule.startDate, schedule.publishDay)
        schedule.endDate = endDate
        return endDate
      }
    },
    changeStandard (val) {
      this.orderBaseData.standard = val
    },
    handleCreateOrder () {
      this.loadingAddOrder = true
      const _this = this

      // 格式化发布档期
      const orderschedules = []
      if (_this.orderBaseData.standard === 1) {
        _this.checkScheduleIndex.forEach(res => {
          orderschedules.push({ startDate: res, endDate: GetDateStr(res, 14) })
        })
      } else {
        _this.customSchduleList.forEach(res => {
          if (res.startDate !== '' && res.endDate !== '') {
            orderschedules.push({ startDate: ParseDate(res.startDate), endDate: ParseDate(res.endDate) })
          }
        })
      }
      _this.orderBaseData.schedules = JSON.stringify(orderschedules)
      _this.orderBaseData.fixed = !_this.orderfixed

      addOrder(_this.orderBaseData).then(res => {
        if (res && res.orderId !== 0) {
          // 获取订单详情， 然后给各个组件传值
          this.$store.commit('set_order_ID', res.orderId)
          this.$store.commit('set_order_pageType', 'edit')
          const query = { orderId: res.orderId }
          this.$store.dispatch('getOrderBaseInfo', query)
        }
        this.loadingAddOrder = false
      }).catch(() => {
        this.loadingAddOrder = false
      })
    },
    initCurrentCustomers () {
      this.customers = []
      this.searchCustomerQuery.publisherId = null
      this.searchCustomerQuery.userId = this.userId
      getAvailableAdvertiserPage(this.searchCustomerQuery).then(res => {
        this.customers = res.list.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
      })
    },
    /**
     * 获取当前媒介主下的所有商业客户
     */
    getAllAdvertiserData () {
      this.searchCustomerQuery.publisherId = this.publisherId
      this.searchCustomerQuery.userId = null
      getAdvertiserPage(this.searchCustomerQuery).then(res => {
        if (res && !res.errcode) {
          this.customers = res.list.map(item => {
            return {
              value: item.id,
              label: item.name
            }
          })
        }
      })
    },
    customerChange () {
      this.contacts = []
      this.brands = []
      this.orderBaseData.contactId = ''
      this.orderBaseData.brandId = ''
      if (this.orderBaseData.advertiserId) {
        const concatequery = {
          advertiserId: this.orderBaseData.advertiserId
        }
        getContactList(concatequery).then(res => {
          this.contacts = res
        })
        const brandquery = {
          advertiserId: this.orderBaseData.advertiserId,
          userId: this.useObject === 1 ? this.userId : null,
          status: '2,3,5',
          group: true
        }
        getBrandList(brandquery).then(res => {
          this.brands = res
        })
      }
    },
    initCustomOption () {
      const _this = this

      this.customScheduleLimit = {
        disabledDate (date) {
          return date.valueOf() < Date.now() - (86400000 * 30 * _this.$store.getters.token.publisherSetting.advancePurchaseTime)
        }
      }
    },
    handleAddCustomSchedule () {
      this.customSchduleList.push({
        startDate: '',
        endDate: '',
        type: 1, // 自定义类型
        period: 1, // 固定周期类型
        publishDay: 1
      })
    },
    handleDeleteCustomSchedule (index) {
      this.customSchduleList.splice(index, 1)
    },
    handlAddContact () {
      this.modalNewContact = true
    },
    contactHandleSubmit (dataname) {
      this.$refs[dataname].validate(valid => {
        if (valid) {
          this.contactData.advertiserId = this.orderBaseData.advertiserId
          addContact(this.contactData).then(res => {
            if (res) {
              this.modalNewContact = false
              const concatequery = {
                advertiserId: this.orderBaseData.advertiserId
              }
              getContactList(concatequery).then(res => {
                this.contacts = res
                // 默认选中新增的联系人
                this.orderBaseData.contactId = res.find(x => x.name === this.contactData.name).id
              })
            }
          })
        }
      })
    }
  },
  computed: {
    disabledNext: function () {
      if (this.orderBaseData.advertiserId === '' || this.orderBaseData.advertiserId === undefined) { return true }
      if (!this.orderContact && (this.orderBaseData.contactId === '' || this.orderBaseData.contactId === undefined)) { return true }
      if (this.orderBaseData.brandId === '' || this.orderBaseData.brandId === undefined) { return true }

      if (this.orderBaseData.standard === 1) {
        return this.checkScheduleIndex.length === 0
      } else {
        let flag = true
        for (let index = 0; index < this.customSchduleList.length; index++) {
          if (this.customSchduleList[index].startDate !== '' && this.customSchduleList[index].endDate !== '' && GetPublishDay(this.customSchduleList[index].startDate, this.customSchduleList[index].endDate) !== '') {
            flag = false
            break
          }
        }

        return flag
      }
    },
    showAddContactButton () {
      return this.orderBaseData.advertiserId && this.orderBaseData.advertiserId !== ''
    }
  }
}
</script>
